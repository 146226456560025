import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth.module';
import UserGroupsTree from "@/types/userGroupsTree";
import IUser from '@/models/IUser';


let userPermitions = {}
const storagePermittions = localStorage.getItem('user-permissions')
if (storagePermittions)
{
  userPermitions = JSON.parse(storagePermittions as string);
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    group: {} as UserGroupsTree,
    selectedGroups: [] as UserGroupsTree[],
    resourceId: null,
    startDate: null,
    endDate: null,
    isObjectsTreeVisible: true,
    userInfo: {} as IUser,
    permissions: userPermitions
  },

  getters: {
    group: state => state.group,
    selectedGroups: state => state.selectedGroups,
    resourceId: state => state.resourceId,
    startDate: state => state.startDate,
    endDate: state => state.endDate,
    isObjectsTreeVisible: state => state.isObjectsTreeVisible,
    userInfo: state => state.userInfo,
    permissions: state => state.permissions,
  },
  mutations: {
    setGroup(state, node) {
      state.group = node;
    },
    setSelectedGroups(state, selectedGroups: UserGroupsTree[]) {
     state.selectedGroups = [];
     selectedGroups.forEach(a => state.selectedGroups.push(a));
    },
    setResourceId(state, resourceId) {
      state.resourceId = resourceId;
    },
    setStartDate(state, startDate) {
      state.startDate = startDate;
    },
    setEndDate(state, endDate) {
      state.endDate = endDate;
    },
    setObjectsTreeVisible(state, isVisible) {
      state.isObjectsTreeVisible = isVisible;
    },
    setUserInfo(state, value) {
      state.userInfo = value;
    },
    setUserPermissions(state, value) {
      state.permissions = value;
    }
  },

  actions: {
    SetGroup(context, node) {      
      context.commit('setGroup', node);
    },
    SetSelectedGroup(context, selectedGroups) {
      context.commit('setSelectedGroups', selectedGroups);
    },
    SetResourceId(context, resourceId) {
      context.commit('setResourceId', resourceId);
    },
    SetStartDate(context, startDate) {
      context.commit('setStartDate', startDate);
    },
    SetEndDate(context, endDate) {
      context.commit('setEndDate', endDate);
    },
    SetObjectsTreeVisible(context, isVisible) {
      context.commit('setObjectsTreeVisible', isVisible);
    },
    SetUserInfo(context, value) {
      localStorage.setItem('user-info', JSON.stringify(value));
      context.commit('setUserInfo', value);
    },
    SetUserPermissions(context, value) {
      localStorage.setItem('user-permissions', JSON.stringify(value));
      context.commit('setUserPermissions', value);
    },
  },

  modules: {
    auth
  }
})
import AuthService from '../services/auth.service';
import jwt_decode from 'jwt-decode'
import AuthInfo from "@/types/authInfo";
import User from '@/types/user';

const authInfoData = localStorage.getItem('auth-info');
let initialState = { loggedIn: false, authInfo: null, user: null }
if (authInfoData) {
    const authInfo = JSON.parse(authInfoData);
    if (authInfo) {
        initialState = {
            loggedIn: true, authInfo, user: jwt_decode(authInfo.access_token)
        }
    }
}
export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }: any, user: User) {
            return AuthService.login(user).then(
                authInfo => {
                    commit('loginSuccess', authInfo);
                    return Promise.resolve(authInfo);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error.response.data);
                }
            );
        },
        logout({ commit }: any) {
            AuthService.logout();
            commit('logout');
            location.reload();
        }
    },
    mutations: {
        loginSuccess(state: any, authInfo: AuthInfo) {
            state.loggedIn = true;
            state.authInfo = authInfo;
            state.user = jwt_decode(authInfo.access_token);
        },
        loginFailure(state: any) {
            state.loggedIn = false;
            state.authInfo = null;
            state.user = null;
        },
        logout(state: any) {
            state.loggedIn = false;
            state.authInfo = null;
            state.user = null;
        }
    }
};

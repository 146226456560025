
import axios from 'axios';
import User from "@/types/user";

class AuthService {
    async login(user: User) {        
        const config = {
            headers: {                
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };
        const bodyFormData = new URLSearchParams();
        bodyFormData.set("grant_type", "password");
        bodyFormData.set("username", user.login);
        bodyFormData.set("password", user.password);
        bodyFormData.set("scope", "api");
        bodyFormData.set("client_id", "EnergyKrugSite");
        bodyFormData.set("client_secret", "EnergyKrugSiteSecret");
        return await axios
            .post(window?.__ENV__?.VUE_APP_IdentityServerPath, bodyFormData, config)
            .then(this.handleResponse)
            .then(response => {
                if (response.data.access_token) {
                    localStorage.setItem('auth-info', JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('auth-info');
    }

    handleResponse(response: any) {
        if (response.status === 401) {
            this.logout();
            const error = response.data && response.data.message;
            return Promise.reject(error);
        }

        return Promise.resolve(response);
    }
}

export default new AuthService();

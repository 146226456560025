import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { eventBus } from '@/main';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/objects/Objects.vue'),
  },
  {
    path: '/dashboards',
    name: 'dashboards',
    component: () => import('../views/Dashboards.vue')
  },
  {
    path: '/mnemonics',
    name: 'mnemonics',
    component: () => import('../views/Mnemonics.vue')
  },
  {
    path: '/monitoringDevices',
    name: 'monitoringDevices',
    component: () => import('../views/MonitoringDevices.vue')
  },
  {
    path: '/monitoringUspd',
    name: 'monitoringUspd',
    component: () => import('../views/MonitoringUspd.vue')
  },
  {
    path: '/monitoringData',
    name: 'monitoringData',
    component: () => import('../views/MonitoringData.vue')
  },
  {
    path: '/vectorDiagram',
    name: 'vectorDiagram',
    component: () => import('../views/VectorDiagram.vue')
  },
  {
    path: '/consumptions',
    name: 'consumptions',
    component: () => import('../views/Consumptions.vue')
  },
  {
    path: '/controlParameters',
    name: 'controlParameters',
    component: () => import('../views/ControlParameters.vue')
  },
  {
    path: '/controlParametersDevice',
    name: 'controlParametersDevice',
    component: () => import('../views/ControlParamDevice.vue')
  },
  {
    path: '/mapControlParam',
    name: 'mapControlParam',
    component: () => import('../views/MapControlParam.vue')
  },
  {
    path: '/journal',
    name: 'journal',
    component: () => import('../views/journal/Journal.vue')
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('../views/reports/Reports.vue'),
    meta: {
      useCheckboxInObjectsTree: true,
    }
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('../views/reports/Report.vue'),
    meta: {
      useCheckboxInObjectsTree: true,
      selectedMenuItemInTopbar: '/reports'
    }
  },
  {
    path: '/graphics',
    name: 'graphics',
    component: () => import('../HMI/trends/views/Analytics/Graphics.vue'),
    meta: {
      useObjectsTree: true,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('auth-info');
  if (authRequired && !loggedIn) {
    return next('/login');
  }
  eventBus.$emit('RefreshTree', to);
  next();
});

export default router
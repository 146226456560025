import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'

import { createYmapsVue2 } from 'vue-yandex-maps';
import * as VueGoogleMaps from 'vue2-google-maps'

import VueApexCharts from 'vue-apexcharts'
import { BootstrapVue, BootstrapVueIcons  } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/template/style.css'
import './assets/template/animate.css'
import './assets/fonts/font-awesome/css/font-awesome.css'
import './assets/style.css'

import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';

// delete Icon.Default.prototype._getIconUrl;
type D = Icon.Default & {
  _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

//import { Stimulsoft } from "./stimulsoft/stimulsoft.blockly.editor.js";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(BootstrapVueIcons )
Vue.component('multiselect', Multiselect);

Vue.use(VueApexCharts)
Vue.component("apexchart", VueApexCharts);

// Vue.use(YmapPlugin, settings);
Vue.use(createYmapsVue2({
  apikey: '60547159-58ed-4f51-9d96-325b0940dc5d',
}));

Vue.use(VueGoogleMaps,{
  load:{
    key: 'AIzaSyCR38v7aa742MGG6QlHmDDdXXpYmvAPCRQ',
    libraries: 'places,drawing,visualizatio',
  }})

Vue.config.productionTip = false

declare global { interface Window { Stimulsoft: any; } }
window.Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHmXoUJcHTHzHx0srE3tGh2VL0Qh/lfalQzXs8QFgF79Ch1Fvv" +
  "W59a6S8GlWUo81AeycOSmDswjbuydXgsk9uZKPejGKZy+EPS15btEk2tJyRP22Ej7vUb7oM+16gBMNT/oh6n/bj5jv" +
  "wtQZfqhv5C7zydXX1ogw94T18H7TOD1r06t5sipp5Z346PQq0q7NuOPKQ1XcsnlYTIDengU/VoXXo4CspY9ykwmxxI" +
  "CwOjnCymnalrIFpGReTbSxHjsM3tRMmYM+M4RmFntq9Gajo7QLe4RFFKpc7ceNvEwgB1R9JXmhiTMnYowu6Qj2trzW" +
  "NlKLm8ei53Cdt+slOqOap6PHIHjs1gUaCiKLa2sEhr0qoz0dnoi574PGl0nI8xytXzYqYZ3Y3bvYQiL08PeqreI1Ek" +
  "qX0UOGb5hv/bLZkDCif542CNHXgmLdn7vB/r7HM2DAHNP446zKBmvrSJ3B4ZPRLXrXV3h3w/Rvukr+2LJ3uMRiQT9C" +
  "zyD973Ua3SaGAl4V133LR7yYLa6F6CXr0/eo3DTw6ujEHCsUV9cqHV1cKo7akzkIakHitf3SX0lHwBM=";
window.Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("stimulsoft/ru.xml");

Vue.filter('formatDateTime', function(value) {
  if (value) {
      return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
  } else {
      return ""
  }
});

export const eventBus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div id="app">
    <div class="top-navigation pace-done" v-if="isLoggedIn">
      <top-bar />
      <div id="wrapper">
        <objects-tree />
        <div id="page-wrapper" class="gray-bg border-top">
          <router-view />
        </div>
      </div>
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from "vue-property-decorator";
  import axios, { AxiosHeaders } from 'axios';
  import PerfectScrollbar from 'vue2-perfect-scrollbar'
  Vue.use(PerfectScrollbar)
  import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

  @Component({
    components: {
      TopBar: () => import('@/components/navigation/TopBar.vue'),
      ObjectsTree: () => import('@/components/navigation/ObjectsTree.vue'),
    }
  })

  export default class App extends Vue {

    get isLoggedIn(): boolean {      
      return this.$store.state.auth.loggedIn;
    }

    mounted() {
      document.title = 'Энергогород'      
      axios.interceptors.request.use(
        function (config) {
          const authInfo = localStorage.getItem("auth-info");
          if (authInfo) {
            const token = JSON.parse(authInfo).access_token;
            if (config.headers) {
              (config.headers as AxiosHeaders).set("Authorization", `Bearer ${token}`);
            }
          }
          return config;
        },
        function (error) {
          console.log(error);
          return Promise.reject(error);
        }
      );
      
      
      axios.interceptors.response.use(null, (error) => {
        switch (error.response.status) {
          case 401:
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
            break;
          case 404:
            break;
        }
        return Promise.reject(error);
      });
    }
  }
</script>

<style lang="scss">
  .border-top {
    border-top: 1px solid #e7eaec !important;
  }
</style>
